import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { isDesktopBrowser } from "../services/utils";

const firebaseConfig = {
  apiKey: "AIzaSyBfD9ytq36h8WT3kxldVDFyQQVKVkzl05k",
  authDomain: "turnup-v3.firebaseapp.com",
  projectId: "turnup-v3",
  storageBucket: "turnup-v3.appspot.com",
  messagingSenderId: "891969577074",
  appId: "1:891969577074:web:4ae738270e5b88cd13a2eb",
};

export let db: firebase.firestore.Firestore,
  auth: firebase.auth.Auth,
  storage: firebase.storage.Storage;

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  db = firebase.firestore();
  auth = firebase.auth();
  storage = firebase.storage();
  db.settings({ ignoreUndefinedProperties: true, merge: true });
  if (
    typeof window !== "undefined" &&
    (window.location.hostname === "localhost" ||
      window.location.hostname.startsWith("192") ||
      window.location.hostname.startsWith("172"))
  ) {
    if (!window.location.href.startsWith("capacitor")) {
      db.settings({
        host: `${window.location.hostname}:8080`,
        ssl: false,
        merge: true,
      });
      if (isDesktopBrowser()) {
        auth.useEmulator("http://localhost:9099");
      }
    }
  }
}
