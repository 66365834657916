import { Loading } from "./Loading";
import React from "react";

export const PageLoading = () => {
  return (
    <div className="container vertical full-height-screen">
      <Loading />
    </div>
  );
};
