const loadTypebot = (): Promise<void> =>
  new Promise((resolve) => {
    const existingScript = document.getElementById("typebot");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://static.typebot.io/typebot-1.0.0.js";
      script.id = "typebot";
      document.body.appendChild(script);
      script.onload = () => {
        resolve();
      };
    }
    if (existingScript) resolve();
  });

export default loadTypebot;
