import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { parseUserFromFirestore } from "./user";
import {
  getFirebaseCloudFunctionsBaseUrl,
  isDesktopBrowser,
  isElectron,
} from "./utils";
import firebase from "firebase/app";
import { User } from "@/types/user";
import { auth, db } from "@/libs/firebase";

declare const window: any;
export const useAuthUser = (
  onUserAuthenticated: (user: any) => void,
  onUserNotAuthenticate: () => void
) =>
  useEffect(() => {
    const cleanAuthListener = auth.onAuthStateChanged(async (user: any) => {
      if (user != null) {
        onUserAuthenticated(user);
      } else {
        onUserNotAuthenticate();
      }
    });
    return function cleanup() {
      cleanAuthListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

export const getAuthUser = () => {
  return new Promise((resolve: any, reject) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user);
      } else {
        reject("User not logged in");
      }
    });
  });
};

export const getRedirectedResult = () => {
  return auth.getRedirectResult();
};

export enum SocialProviders {
  Facebook = "facebook.com",
  Google = "google.com",
  Apple = "apple.com",
}

export const socialAuthentication = async (providerType: SocialProviders) => {
  if (isElectron()) {
    window.ipcRenderer.send(
      "open-link",
      providerType === SocialProviders.Google
        ? `${window.location.origin}/desktop-auth/google`
        : `${window.location.origin}/desktop-auth/facebook`
    );
    window.ipcRenderer.on("auth", async (_: any, data: any) => {
      if (data.status && data.status.fail) return;
      let credential;
      if (data.providerId === "google.com") {
        credential = firebase.auth.GoogleAuthProvider.credential(
          data.oauthIdToken
        );
      } else {
        credential = firebase.auth.FacebookAuthProvider.credential(
          data.oauthAccessToken
        );
      }
      await auth.signInWithCredential(credential);
    });
    return;
  }
  if (!Capacitor.isNativePlatform()) {
    const provider =
      providerType === SocialProviders.Facebook
        ? new firebase.auth.FacebookAuthProvider()
        : new firebase.auth.GoogleAuthProvider();
    provider.addScope("email");
    if (isDesktopBrowser()) {
      return auth.signInWithPopup(provider);
    }
    return auth.signInWithRedirect(provider);
  }
  const { cfaSignIn } = await import("capacitor-firebase-auth");
  return new Promise<void>((resolve, reject) => {
    cfaSignIn(providerType).subscribe(
      () => resolve(),
      (error: any) => reject(error)
    );
  });
};

export const emailSignUp = (email: string, password: string) => {
  return auth.createUserWithEmailAndPassword(email, password);
};

export const emailSignIn = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

const updateUserToken = async (authUser: any, user: User) => {
  const token = await authUser.getIdTokenResult();
  if (!token.claims.databaseUserId) {
    const updateUserTokenUrl = `${getFirebaseCloudFunctionsBaseUrl()}/updateUserIdAuthToken?email=${
      user.email
    }&uid=${authUser.uid}`;
    await fetch(updateUserTokenUrl);
    await authUser.getIdToken(true);
  }
};

export const getOrCreateUser = async (
  auth: any,
  userFromState?: User
): Promise<User | undefined> => {
  const info = [...auth.providerData].shift();
  if (!info) return;
  const email = info.email ?? auth.email;
  if (!email) return;
  let photoUrl = info.photoURL;
  if (info.providerId === "facebook.com") {
    photoUrl += "?height=300";
  }
  const user = {
    firstName: info.displayName ?? "",
    email,
    photoUrl,
  };

  let existingUser = await getUserByEmail(user.email);
  if (existingUser) {
    await updateUserToken(auth, existingUser);
    return existingUser;
  }
  let id = auth.uid;
  if (userFromState) {
    id = userFromState.id;
  }
  await db
    .collection("users")
    .doc(id)
    .set({ ...user, createdDate: new Date() });

  const newUser = { ...user, id, isGuest: false, tokens: {} };
  await updateUserToken(auth, newUser);
  return newUser;
};

export const linkFacebookToGuest = () => {
  const currentAuthUser = auth.currentUser!;
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope("email");
  currentAuthUser.linkWithRedirect(provider);
};

export const signUpAnonymously = async () => {
  await auth.signInAnonymously();
};

export const getUserByEmail = async (
  email: string
): Promise<User | undefined> => {
  const resultExistingUser = await db
    .collection("users")
    .where("email", "==", email)
    .get();
  if (!resultExistingUser.docs[0]) {
    return;
  }

  return parseUserFromFirestore(resultExistingUser.docs[0]);
};

export const getUserById = async (id: string) => {
  const resultExistingUser = await db.collection("users").doc(id).get();
  if (!resultExistingUser) {
    return;
  }
  return parseUserFromFirestore(resultExistingUser);
};

export const logOut = async () => {
  const { cfaSignOut } = await import("capacitor-firebase-auth");
  return new Promise<void>((resolve) => {
    cfaSignOut().subscribe(() => {
      resolve();
    });
  });
};
