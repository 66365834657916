import { db } from "@/libs/firebase";
import { SavedMusic } from "@/types/room";
import { User } from "@/types/user";
import { getFirebaseCloudFunctionsBaseUrl } from "./utils";

export const addSavedMusic = (userId: string, musicsSaved: SavedMusic[]) =>
  db.collection("savedMusics").doc(userId).set({ musics: musicsSaved });

export const removeSavedMusic = (userId: string, musicsSaved: SavedMusic[]) =>
  db.collection("savedMusics").doc(userId).set({ musics: musicsSaved });

export const getUserSavedMusics = async (
  userId: string
): Promise<SavedMusic[]> => {
  const savedMusicsSnapshot = await db
    .collection("savedMusics")
    .doc(userId)
    .get();
  return parseSavedMusicsFromFirestore(savedMusicsSnapshot);
};

const parseSavedMusicsFromFirestore = (doc: any): SavedMusic[] => {
  if (!doc.exists) return [];
  return doc
    .data()!
    .musics.map((music: { addedDate: { toDate: () => any } }) => ({
      ...music,
      addedDate: music.addedDate.toDate(),
    }));
};

export const parseUserFromFirestore = (doc: any): User | undefined => {
  if (!doc.data()) return;
  return {
    id: doc.id,
    email: doc.data()!.email,
    firstName: doc.data()!.firstName,
    photoUrl: doc.data()!.photoUrl,
    canCreateClub: doc.data()!.canCreateClub,
    plan: doc.data()!.plan,
    stripeCustomerId: doc.data()!.stripeCustomerId,
    isGuest: doc.data()!.isGuest ?? doc.data()!.email.endsWith("guest.com"),
    tokens: doc.data()!.tokens ?? [],
  };
};

export const parseGuest = async (firstName: string): Promise<User> => {
  const userRef = db.collection("users").doc();
  await userRef.set({
    firstName,
    email: userRef.id + "@guest.com",
    createdDate: new Date(),
    isGuest: true,
  });
  return {
    id: userRef.id,
    firstName,
    email: userRef.id + "@guest.com",
    isGuest: true,
    tokens: {},
  };
};

export const subscribeToUserUpdates = (userId: string, cb: Function) =>
  db
    .collection("users")
    .doc(userId)
    .onSnapshot((doc) => {
      cb(parseUserFromFirestore(doc));
    });

export const insertToken = async (userId: string, roomId: string) => {
  const response = await fetch(
    `${getFirebaseCloudFunctionsBaseUrl()}/insertToken?userId=${userId}&roomId=${roomId}`
  );
  const data = await response.json();
  console.log(data);
  return data.message;
};

export const isGod = (userId: string) =>
  userId === "gxlnO4sAnFMfXJskhyYA671aOcg1";
