import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Button,
  Flex,
  Heading,
  HStack,
  LightMode,
  Text,
  VStack,
  Image,
  Box,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { Capacitor } from "@capacitor/core";
import { Conversation } from "@/assets/illustrations/Conversation";
import { LinkBar } from "@/assets/illustrations/LinkBar";
import { Logo } from "@/components/Logo";
import { PageLoading } from "@/components/PageLoading";
import loadTypebot from "@/libs/typebot";
import { useAuthUser } from "@/services/authentication";
import { useStoreState } from "@/store";
import Link from "next/link";

declare const Typebot: {
  Chat: (arg0: {
    publishId: string;
    buttonColor: string;
    loadingBackgroundColor: string;
  }) => any;
};

const LandingPage: React.FC = () => {
  const router = useRouter();
  const currentUser = useStoreState((state) => state.currentUser.value);
  const [pageLoading, setPageLoading] = useState(true);

  useAuthUser(
    async (_) => {
      router.replace("/me");
    },
    () => setPageLoading(false)
  );

  useEffect(() => {
    if (currentUser || Capacitor.isNativePlatform()) {
      router.replace("/me");
      return;
    }
    let typebot: any;
    loadTypebot().then(() => {
      typebot = Typebot.Chat({
        publishId: "jamhouse",
        buttonColor: "#1D0D27",
        loadingBackgroundColor: "#1D0D27",
      });
    });

    return () => {
      try {
        if (typebot) typebot.remove();
      } catch (err) {}
    };
    // eslint-disable-next-line
  }, [router]);

  if (pageLoading) {
    return <PageLoading />;
  }
  return (
    <Flex justifyContent="center" bgColor="gray.900" overflowX="hidden">
      <Flex maxW="1200px" alignItems="center" flexDirection="column">
        <Flex flexDirection="column" minH="100vh" pos="relative">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            p={4}
            pos={{ lg: "absolute" }}
            top="0"
            w="100%"
            maxW="1200px"
          >
            <HStack>
              <Logo height="40px" width="40px" />
              <Heading fontSize="2xl">Jamhouse</Heading>
            </HStack>

            <HStack>
              <Button onClick={() => router.push("/login")}>Sign in</Button>
            </HStack>
          </Flex>
          <Stack
            direction={["column", "row"]}
            flex="1"
            align="center"
            spacing="6"
          >
            <VStack
              px={{ base: 4, lg: 0 }}
              alignItems={{ lg: "flex-start", base: "center" }}
              spacing={6}
              flex="1"
              mt={{ lg: 0, base: 10 }}
            >
              <Heading size="2xl" textAlign={{ lg: "start", base: "center" }}>
                Listen to music with friends
              </Heading>
              <Text
                fontWeight="medium"
                fontSize="lg"
                textAlign={{ lg: "start", base: "center" }}
              >
                Create a room, share the link with friends, and start listening
                to the same music. No music streaming account is required. It's
                100% free.
              </Text>
              <LightMode>
                <Button
                  colorScheme="orange"
                  size="lg"
                  onClick={() => router.replace("/login")}
                >
                  Create my room
                </Button>
              </LightMode>
            </VStack>
            <Flex pos="relative" flex="1" h={{ lg: "600px" }}>
              <Box>
                <Image
                  alt="mockups"
                  src={"/mockups.png"}
                  maxW={{ lg: "1200px", base: "full" }}
                  pos={{ lg: "absolute" }}
                  top="0"
                />
              </Box>
            </Flex>
          </Stack>
          <Flex
            w="100%"
            maxW="1200px"
            pos={{ lg: "absolute" }}
            bottom="0"
            justifyContent="center"
            alignItems="center"
            fontSize="30px"
          >
            👇
          </Flex>
        </Flex>

        <VStack mt={10} py="24" w="full">
          <Heading textAlign={{ base: "center", lg: "start" }} mb="6">
            Create your own room link
          </Heading>
          <Box w="full">
            <LinkBar width="100%" height="100%" />
          </Box>
        </VStack>
        <VStack spacing={10} py="24" w="full">
          <Heading textAlign={{ base: "center", lg: "start" }} mb="6">
            Friends can join in one click
          </Heading>
          <Box w="full" maxW="600px">
            <Conversation width="100%" height="100%" />
          </Box>
        </VStack>
        <VStack py="24">
          <Heading textAlign={{ base: "center", lg: "start" }} mb="10">
            Anyone can participate in the playlist
          </Heading>
          <Box as="section">
            <Box
              maxW={{ base: "xl", md: "5xl" }}
              mx="auto"
              px={{ base: "6", md: "8" }}
            >
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                spacingX="10"
                spacingY="14"
              >
                <Feature title="Search for any song" icon="🔍">
                  Jamhouse is powered by Youtube Music. You can search and add
                  any song from this huge library.
                </Feature>
                <Feature title="Vote for songs you like" icon="🔥">
                  The playlist is adjusted in real-time based on the number of
                  votes on each song.
                </Feature>
                <Feature title="Vote to skip the current song" icon="⏭">
                  You don't like the currently playing song? It will be skipped
                  if the majority of participants clicked on the skip button.
                </Feature>
                <Feature title="Save discovered songs" icon="❤️">
                  Saved discovered songs into your favorite list that you can
                  access any time
                </Feature>
              </SimpleGrid>
            </Box>
          </Box>
        </VStack>
        <VStack py="10">
          <Heading mb="4">Try Jamhouse now</Heading>
          <LightMode>
            <Button
              colorScheme="orange"
              size="lg"
              onClick={() => router.push("/login")}
            >
              Create my room
            </Button>
          </LightMode>
        </VStack>
        <Box as="footer" role="contentinfo" py="6" w="full">
          <Flex
            direction={{ base: "column", md: "row" }}
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
            align="center"
          >
            <Link
              aria-current="page"
              aria-label="Back to Home page"
              href="/"
              passHref
            >
              <Logo height="40px" width="40px" />
            </Link>
            <Stack
              my={{ base: "6", md: 0 }}
              direction={{ base: "column", md: "row" }}
              marginStart={{ md: "8" }}
              fontSize="sm"
              spacing={{ base: "2", md: "8" }}
              textAlign={{ base: "center", md: "start" }}
            >
              <Text>
                Made with ❤️ by{" "}
                <Link href="https://twitter.com/baptisteArno">
                  @baptisteArno
                </Link>
              </Text>
              <Link href="https://www.iubenda.com/privacy-policy/26640975">
                Privacy
              </Link>
              <Link href="https://bot.typebot.io/jamhouse">Contact</Link>
            </Stack>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

interface FeatureProps {
  title: string;
  children: string;
  icon: string;
}
const Feature = (props: FeatureProps) => {
  const { title, children, icon } = props;
  return (
    <Stack spacing="6" direction={{ base: "column", md: "row" }}>
      <Box fontSize="6xl">{icon}</Box>
      <Stack spacing="1">
        <Text fontWeight="extrabold" fontSize="lg">
          {title}
        </Text>
        <Box color={"gray.400"}>{children}</Box>
      </Stack>
    </Stack>
  );
};

export default LandingPage;
